import styled from "styled-components";

const StyledFooter = styled.footer`
  padding: 16px;
  margin: 0 auto;
  max-width: 1140px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: thin solid #ccc;
  flex-wrap: wrap;
  div {
    display: flex;
    align-items: flex-end;
    order: 2;
    a {
      text-decoration: none;
      color: #191c20;
      margin-right: 32px;
    }
    @media screen and (max-width: 768px) {
      display: block;
      order: 4;
      margin: 5px 16px 0 0;
    }
  }
`;

const StyledDisclaimer = styled.div`
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const Footer = () => (
  <StyledFooter>
    <StyledDisclaimer>
      © 2022 by Aishwarya Chaturvedi. All rights reserved.
    </StyledDisclaimer>
    <div>
      <a
        href="https://github.com/AishwaryaChat"
        target="_blank"
        rel="noreferrer"
      >
        Github
      </a>
      <a
        href="https://www.linkedin.com/in/aishwarya-chaturvedi-035b268b/"
        target="_blank"
        rel="noreferrer"
      >
        Linkedin
      </a>
    </div>
  </StyledFooter>
  // <div className="container">
  //   <div className="divider" />
  //   <div className="footer-container">
  //     <div className="float-left">
  //       © 2021 by Aishwarya Chaturvedi. All rights reserved.
  //     </div>
  //     <div className="float-right">
  //       <nav className="navbar">
  //         <span className="nav-items b uppercase">
  //           <a href="https://github.com/AishwaryaChat"  target="_blank" rel="noreferrer">Github</a>
  //         </span>
  //         <span className="nav-items b uppercase">
  //           <a href="https://www.linkedin.com/in/aishwarya-chaturvedi-035b268b/" target="_blank" rel="noreferrer">Linkedin</a>
  //         </span>
  //       </nav>
  //     </div>
  //   </div>
  // </div>
);

export default Footer;
