import styled from "styled-components";

const StyledHome = styled.div`
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 26px;
    }
  }
  a {
    text-decoration: none;
    color: #2b2d2f;
    cursor: pointer;
    border-bottom: thin #2b2d2f solid;
  }
`;

export default StyledHome;
