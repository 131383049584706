import Layout from "components/Layout/layout";
import StyledHome from "./styledHome";

const Home = () => {
  return (
    <Layout>
      <StyledHome>
        <div>
          <h1>Hello, I am Aishwarya.</h1>
          <div>
            <p>
              I am a Software Developer/Engineer based in Bengaluru, India.
              Currently I'm working at{" "}
              <a
                className="opacity-80 company-tag"
                href="https://www.linkedin.com/company/walmartglobaltechindia/mycompany/"
              >
                {" "}
                Walmart.
              </a>
            </p>
            <p>
              I have mostly worked on React and Nodejs througout my experience
              of 5+ years.
            </p>
          </div>
          <p className="margin-top-20 font-size-20 font-style-italic">
            Currently learning creating electron apps.
          </p>
        </div>
      </StyledHome>
    </Layout>
  );
};

export default Home;
