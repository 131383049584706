import { createGlobalStyle } from "styled-components";
import Header from "components/Header/header";
import Body from "components/Body/body";
import Footer from "components/Footer/footer";

const GlobalStyles = createGlobalStyle`
    body {
        padding: 0;
        margin: 0;
        font-family: 'Lato', sans-serif;
        height: 100%;
    }
`;

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = (props: LayoutProps) => {
  return (
    <>
      <GlobalStyles />
      <Header />
      <Body>{props.children}</Body>
      <Footer />
    </>
  );
};

export default Layout;
