import styled from "styled-components";

const StyledWork = styled.div`
  a {
    text-decoration: none;
    color: #2b2d2f;
    font-size: 18px;
    font-weight: 600;
    border-bottom: thin #2b2d2f solid;
    cursor: pointer;
  }
  p {
    font-style: italic;
  }
`;

export default StyledWork;
