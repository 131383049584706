import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "views/Home/home";
import ComingSoon from "components/comingSoon";
import Work from "views/Work/work";
import BeginnerProjects from "views/BeignnerProjects/beginnerProjects";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="work" element={<Work />} />
        <Route path="blog" element={<ComingSoon />} />
        <Route path="beginnerProjects" element={<BeginnerProjects />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
