import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: #191c20;
`;

export const StyledTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  cursor: pointer;
  color: #191c20;
  order: 1;
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
`;

export const StyledHeader = styled.header`
  width: 90%;
  margin: 0 auto;
  padding: 16px;
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: thin solid #ccc;
  margin-bottom: 30px;
  
  nav {
    order: 2;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 32px;
    span {
      margin-left: 32px;
      font-size: 18px;
      line-height: 1.5;
      font-weight: 400;
    }
  }
  @media screen and (max-width: 768px) {
    align-items: flex-start;
    nav {
      justify-content: flex-start;
      span {
        font-size: 16px;
        margin: 5px 16px 0 0;
        line-height: 2;
      }
    }
  }
`;