import Layout from "components/Layout/layout";
import { WORK_MAP, Work_Map_Obj_Type } from "./constants";
import StyledWork from "./styledWork";

const Work = () => {
  return (
    <Layout>
      <StyledWork>
        {WORK_MAP.map((w: Work_Map_Obj_Type, i) => (
          <div key={i}>
            <a href={w.githubUrl} target="_blank" rel="noreferrer">
              {w.title}
            </a>
              <p>{w.description1}</p>
              <p>{w.description2}</p>
          </div>
        ))}
      </StyledWork>
    </Layout>
  );
};

export default Work;
