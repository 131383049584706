import Layout from "components/Layout/layout";
import StyledATag from "components/StyledATag"
const BeginnerProjects = () => {
  return (
    <Layout>
      <div>
        <p>
          Here is the list of some beginner friendly projects, that I have been
          creating for newbie programmers. The projects are written using
          Javascript, Typescript and Nodejs. These are simple yet informative
          projects which might help you in starting with your javascript career.
          Enjoy!!!
        </p>

        <div>
          <ul>
            <li>
              <StyledATag
                className="opacity-80 company-tag"
                href="https://github.com/AishwaryaChat/Tic-Tac-Toe"
              >
                {" "}
                Tic-Tac-Toe Game
              </StyledATag>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default BeginnerProjects;
