import Layout from "components/Layout/layout"
import StyledComingSoon from "./styledCommingSoon"

const ComingSoon = () => (
    <Layout>
        <StyledComingSoon>
        <h1>Coming Soon.......</h1>
        </StyledComingSoon>
    </Layout>
)

export default ComingSoon