import { useState } from "react";
import StlyedPopover, { StyledTypography } from "./styledComponents";

const PopoverComponent = ({ popoverText, children }) => {
  const [popoverRef, setPopoverRef] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(popoverRef);
  const handlePopoverClick = (e) => {
    setPopoverRef(e.currentTarget);
  };
  const handleClose = () => {
    setPopoverRef(null);
  };

  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <StyledTypography aria-describedby={id} onClick={handlePopoverClick}>
        <span>{popoverText}</span>
      </StyledTypography>
      <StlyedPopover
        id={id}
        open={open}
        anchorEl={popoverRef}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {children}
      </StlyedPopover>
    </>
  );
};

export default PopoverComponent;
