import styled from "styled-components";
import { Popover } from "@mui/material";
import { Typography } from "@mui/material";

const StlyedPopover = styled(Popover)`
  margin: 10px;
  & .MuiPopover-paper {
    padding: 20px;
  }
`;

export const StyledTypography = styled(Typography)`
  color: #191c20;
  cursor: pointer;
`;

export default StlyedPopover;
