export const WORK_MAP: Array<Work_Map_Obj_Type> = [
  {
    title: "LISP Interpreter",
    githubUrl: "https://github.com/AishwaryaChat/Lisp-Interpreter.git",
    description1: "The project has a executable script, for which the input is a string, which should be a list of LISP instructions. The script takes in the input and return back an array of print statements which were there in the LISP instruction.",
    description2: "The idea behind creating this project was to learn how interpreters work internally and as I have a keen interest in knowing how things work internally so I started with this project."
  },
];

export type Work_Map_Obj_Type = {
  title: string;
  githubUrl: string;
  description1: string;
  description2: string;
};
