import styled from "styled-components";

const Body = styled.main`
  width: 90%;
  margin: 0 auto;
  max-width: 1024px;
  min-height: 80vh;
  p {
    font-size: 18px;
    line-height: 1.5;
  }
`;

export default Body;
