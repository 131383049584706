import { StyledHeader, StyledLink, StyledTitle } from "./styledComponents";
import { Divider, Typography } from "@mui/material";
import Popover from "components/Popover/popover";

const Header = () => {
  return (
    <StyledHeader>
      <StyledTitle>
        <StyledLink to="/">Aishwarya Chaturvedi</StyledLink>
      </StyledTitle>
      <nav>
        <Popover popoverText="Projects">
          <Typography sx={{ p: 1 }}>
            <StyledLink to="/work">Work</StyledLink>
          </Typography>
          <Divider />
          <Typography sx={{ p: 1 }}>
            <StyledLink to="/beginnerProjects">Novice</StyledLink>
          </Typography>
        </Popover>
        <StyledLink to="/blog">
          <span>Blog</span>
        </StyledLink>
      </nav>
    </StyledHeader>
  );
};

export default Header;
